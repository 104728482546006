@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@layer base {
    :root {
        --color-primary-50: #FEF2F2;
        --color-primary-100: #FEE2E2;
        --color-primary-200: #FECACA;
        --color-primary-300: #FCA5A5;
        --color-primary-400: #F87171;
        --color-primary-500: #EF4444;
        --color-primary: #DC2626;
        --color-primary-700: #B91C1C;
        --color-primary-800: #991B1B;
        --color-primary-900: #7F1D1D;
    }
}